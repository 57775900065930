import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Login from "./views/Login/Login";
import Task from "./views/Task/Task";
import Rules from "./views/Rules/Rules";
import Ranking from "./views/Ranking/Ranking";
import setupAxios from "./utils/SetupAxios";

function App() {
	setupAxios();

	return (
		<Router>
			<Routes>
				<Route path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
				<Route path="/task" element={<Task />} />
				<Route path="/rules" element={<Rules />} />
				<Route path="/ranking" element={<Ranking />} />
			</Routes>
		</Router>
	);
}

export default App;
