import axios from "axios";
import Environment from "../Environment";

export default function setupAxios() {
  axios.defaults.baseURL = Environment.API_URL;

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      const status = error.response && error.response.status;

      if (status === 401) {
        localStorage.removeItem("token")
        window.location.href = '/login'
      }

      return Promise.reject(error);
    },
  );

  axios.interceptors.request.use((config: any) => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  return axios;
}
