function getEnv(env: string, defaults: any) {
  return process.env[env] || defaults;
}

const Environment = {
  ENV: getEnv("NODE_ENV", "development"),
  API_URL: getEnv("REACT_APP_API_URL", "http://localhost:3000/v1"),
  GOOGLE_CLIENT_ID: getEnv("REACT_APP_GOOGLE_CLIENT_ID", "950828440020-e0e4nqfmvakers0vf9rs6hvdd09frlv9.apps.googleusercontent.com"),
};

export default Environment;