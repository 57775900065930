import logo from "../../assets/lunos_logo.png";
import background from "../../assets/stars_background.png";
import webBackground from "../../assets/login_background.png";
import "./Login.css";
import { GoogleLogin, useGoogleLogout } from "react-google-login";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Environment from "../../Environment";

function Login() {
	const navigate = useNavigate();
	const { signOut } = useGoogleLogout({
		clientId: Environment.GOOGLE_CLIENT_ID,
	});

	const [width, setWidth] = useState(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	const isMobile = width <= 768;

	useEffect(() => {
		function init() {
			const token = localStorage.getItem("token");
			if (token) {
				navigate("/task");
			} else {
				signOut();
			}
		}
		init();
	}, [navigate, signOut]);

	const onSuccess = async (googleUser: any) => {
		try {
			const { data } = await axios.post("/auth/loginWithGoogle", {
				tokenId: googleUser.tokenId,
			});
			localStorage.setItem("token", data.accessToken);
			navigate("/task");
		} catch (error) {
			return error;
		}
	};

	return (
		<div className="Login">
			<header className="Login-header">
				<img src={logo} alt="logo" className="Login-logo" />
			</header>
			<div className="Login-body">
				<img
					src={isMobile ? background : webBackground}
					alt="stars_background"
					className={isMobile ? "Login-background" : "Login-web-background"}
				/>
				<br /> <br /> <br />
				<p className="challenge">DESAFIO</p>
				<p className="save-money">Guardar dinheiro</p>
				<p className="enter-challenge">Continue para entrar no desafio</p>
				<GoogleLogin
					className="google-button"
					clientId={Environment.GOOGLE_CLIENT_ID}
					buttonText="Continuar com Google"
					onSuccess={onSuccess}
					cookiePolicy={"single_host_origin"}
					disabled={false}
				/>
			</div>
		</div>
	);
}

export default Login;
