import logo from "../../assets/lunos_logo.png";
import "./Task.css";
import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined";
import WhatsappOutlinedIcon from "@mui/icons-material/WhatsappOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { Link } from "react-router-dom";
import { Widget } from "@typeform/embed-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

function Task() {
	const navigate = useNavigate();
	const [tasks, setTasks] = useState([])

	const [currentTaskIndex, setCurrentTaskIndex] = useState(0)
	const [formId, setFormId] = useState(null)
	const [taskId, setTaskId] = useState(null)
	const dayCount = Number(localStorage.getItem('dayCount')) || 1
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetch() {
			try {
				const { data } = await axios.get('/tasks');
				if(!data || !data.length) {
					return navigate("/ranking");
				}
				setTasks(data)
				setFormId(data[0].typeformId)
				setTaskId(data[0].id)
			} catch (error) {
				return error;
			}
		}
		fetch()
	}, [navigate])

	return (
		<div className="Task">
			<header className="Task-header">
				<img src={logo} alt="logo" className="Task-logo" />
				<Link to="/rules">
					<QuestionMarkOutlinedIcon
						className="Task-icon-question-mark"
						fontSize="16"
					/>
				</Link>
			</header>
			<div className="Task-body">
				{!loading && (
					<div className="Task-horizontal">
						<p className="Task-day-bold">DIA {dayCount} </p>
						<p className="Task-day">Esta pergunta expira às 20h</p>
					</div>
				)}
				<div className="Task-container">
					{loading ? (
						<>
							<lottie-player src="https://assets6.lottiefiles.com/packages/lf20_lmj8ny4j.json"  background="transparent"  speed="1"  style={{width: "100%", height: "100%", top: 0, left: 0, position: "absolute"}} loop  autoplay></lottie-player>
							<div className="Task-loading">
								<CircularProgress color="secondary" style={{marginRight: 20}} />
								Aguarde, estamos processando sua resposta...
							</div>
						</>
					) :
					(formId && (
						<Widget
							key={formId}
							id={formId}
							style={{ width: "100%", height: "500px" }}
							className="Task-margin-bottom"
							onSubmit={(res) => {
								setLoading(true);
								setTimeout(async () => {
									try {
										await axios.post(`/tasks/${taskId}/complete`, {
											responseId: res.responseId,
										});
										
										if(tasks.length > 1) {
											setFormId(tasks[currentTaskIndex + 1].typeformId)
											setTaskId(tasks[currentTaskIndex + 1].id)
											setCurrentTaskIndex(currentTaskIndex + 1)
											return
										}
										localStorage.setItem('dayCount', dayCount + 1)
										navigate("/ranking");
									} catch (error) {
										return error;
									} finally {
										setLoading(false)
									}
								}, 2000)
							}}
						/>
					))}
				</div>
				<div>
					<SentimentNeutralOutlinedIcon className="Task-icon" />
					<a
						href={"https://t.me/+asqOOU0gM3MyYzZh"}
						target="_blank"
						rel="noreferrer"
						className="Task-hyperlinks"
					>
						Preciso de ajuda
					</a>
				</div>
				<div className="Task-margin-bottom">
					<WhatsappOutlinedIcon className="Task-icon" />
					<a
						href={"https://t.me/+asqOOU0gM3MyYzZh"}
						target="_blank"
						rel="noreferrer"
						className="Task-hyperlinks"
					>
						Entrar no grupo do WhatsApp
					</a>
				</div>
			</div>

		</div>
	);
}

export default Task;
