import logo from "../../assets/lunos_logo.png";
import "./Ranking.css";
import SentimentNeutralOutlinedIcon from "@mui/icons-material/SentimentNeutralOutlined";
import WhatsappOutlinedIcon from "@mui/icons-material/WhatsappOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import background from "../../assets/ranking_background.png";
import avatar from "../../assets/avatar.png";
import crown from "../../assets/crown.png";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import axios from "axios";

function Ranking() {
	const navigate = useNavigate();
	const [scoreboard, setScoreboard] = useState([])
	const tomorrow = new Date();
	tomorrow.setHours(20, 0, 0, 0);

	const renderer = ({ hours, minutes, seconds, completed }) => {
		// Render a countdown
		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return (
			<span>
				{hours}:{minutes}:{seconds}
			</span>
		);
	};

	useEffect(() => {
		async function fetch() {
			try {
				const { data } = await axios.get('/tasks/scoreboard');
				setScoreboard(data)
			} catch (error) {
				return error;
			}
		}
		async function fetchTasks() {
			try {
				const { data } = await axios.get('/tasks');
				if(data && data.length) {
					return navigate("/task");
				}
			} catch (error) {
				return error
			}
		}
		fetchTasks()
		fetch()
	}, [navigate])

	return (
		<div className="Ranking" style={{backgroundImage: `url(${background})`}}>
			<header className="Ranking-header">
				<img src={logo} alt="logo" className="Ranking-logo" />
				<Link to="/rules">
					<QuestionMarkOutlinedIcon
						className="Ranking-icon-question-mark"
						fontSize="16"
					/>
				</Link>
			</header>
			<div className="Ranking-body">
				<p className="Ranking-next-challenge">Próximo desafio em</p>
				<p className="Ranking-countdown">
					<Countdown date={tomorrow} renderer={renderer} />
				</p>

				<p className="Ranking-italic">
					Um novo desafio, todos os dias, às 20h.
				</p>
				<p className="Ranking-general">Ranking geral</p>

				{(scoreboard && scoreboard.length) ? 
					(scoreboard.map((s, i) => (
						<div className="Ranking-horizontal Ranking-row" key={s.user_id}>
							<p className="Ranking-number">{i + 1}</p>
							<div className="Ranking-holder">
								<div className="Ranking-horizontal">
									{i === 0 && (
										<img src={crown} alt="avatar" className="Ranking-crown" />
									)}
									<img src={s.avatar || avatar} alt="avatar" className="Ranking-avatar" />
									<p className="Ranking-name">{s.name}</p>
								</div>
								<p className="Ranking-points">{s.score}pts</p>
							</div>
						</div>
					))) : (Array.from(Array(6), (e, i) => (
						<Stack direction="row" width={"100%"} spacing={2} mb={3} key={i}>
							<Skeleton variant="circular" width={42} height={42} />
							<Skeleton variant="text" width={"100%"} />
						</Stack>
					)))
				}
				<div className="Ranking-margin-top">
					<SentimentNeutralOutlinedIcon className="Ranking-icon" />
					<a
						href={"https://t.me/+asqOOU0gM3MyYzZh"}
						target="_blank"
						rel="noreferrer"
						className="Ranking-hyperlinks"
					>
						Preciso de ajuda
					</a>
				</div>
				<div className="Ranking-margin-bottom">
					<WhatsappOutlinedIcon className="Ranking-icon" />
					<a
						href={"https://t.me/+asqOOU0gM3MyYzZh"}
						target="_blank"
						rel="noreferrer"
						className="Ranking-hyperlinks"
					>
						Entrar no grupo do WhatsApp
					</a>
				</div>
			</div>
		</div>
	);
}

export default Ranking;
