import logo from "../../assets/lunos_logo.png";
import "./Rules.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import coin from "../../assets/coin.png";
import trophy from "../../assets/trophy.png";
import calendar from "../../assets/calendar.png";
import { useNavigate } from "react-router-dom";

function Rules() {
	const navigate = useNavigate();

	return (
		<div className="Rules">
			<header className="Rules-header" onClick={() => navigate(-1)}>
				<img src={logo} alt="logo" className="Rules-logo" />
				<CloseOutlinedIcon className="Rules-icon-close" />
			</header>
			<div className="Rules-body">
				<p className="Rules-title">Como funciona</p>
				<p className="Rules-normal-text">
					Todo dia você terá acesso a um novo desafio para responder. Para cada
					tarefa completa ganhará pontos.
				</p>
				<div className="Rules-horizontal">
					<img src={coin} alt="coin" className="Rules-coin" />
					<p className="Rules-subheader-text">Pontuação</p>
				</div>
				<div>
					<p className="Rules-subheader-margin-text">Tarefas nível fácil:</p>
					<ul>
						<li className="Rules-bullet-points">
							<b>100 pontos</b> para o primeiro que acertar (então seja rápido);
						</li>
						<li className="Rules-bullet-points">
							<b>95 pontos</b> para o segundo que acertar;
						</li>
						<li className="Rules-bullet-points">
							<b>90 pontos</b> para o terceiro que acertar;
						</li>
						<li className="Rules-bullet-points">
							<b>85 pontos</b> para todos que acertarem na sequência;
						</li>
						<li className="Rules-bullet-points">
							<b>15 pontos</b> para quem errar (sim, somos bonzinhos).
						</li>
					</ul>
				</div>
				<div>
					<p className="Rules-subheader-margin-text">Tarefas nível moderado:</p>
					<ul>
						<li className="Rules-bullet-points">
							<b>150 pontos</b> para o primeiro que preencher o formulário;
						</li>
						<li className="Rules-bullet-points">
							<b>145 pontos</b> para o segundo que preencher o formulário;
						</li>
						<li className="Rules-bullet-points">
							<b>140 pontos</b> para o terceiro que preencher o formulário;
						</li>
						<li className="Rules-bullet-points">
							<b>135 pontos</b> para todos que preencherem o formulário na
							sequência.
						</li>
					</ul>
				</div>
				<div>
					<p className="Rules-subheader-margin-text">Tarefas nível difícil:</p>
					<ul>
						<li className="Rules-bullet-points">
							<b>300 pontos</b> para todos que conseguirem poupar todo o valor
							escolhido como meta. Pontuação proporcional para quem poupou
							apenas uma parte (vai, nem é tão difícil assim).
						</li>
					</ul>
				</div>
				<div>
					<div className="Rules-horizontal">
						<img src={calendar} alt="coin" className="Rules-calendar" />
						<p className="Rules-subheader-text"> Duração do desafio</p>
					</div>
					<p className="Rules-normal-text">
						O desafio acontecerá de segunda à sexta, entre os dias 00/05 à
						00/06.
					</p>
				</div>
				<div>
					<div className="Rules-horizontal">
						<img src={trophy} alt="coin" className="Rules-coin" />
						<p className="Rules-subheader-text">Premiação</p>
					</div>
					<p className="Rules-normal-text">
						Ocorrerá ao final de cada semana. A cada início de semana, a
						pontuação é zerada e você ganha uma nova chance de ser premiado.{" "}
						<br /> <br /> A recompensa será dada aos 3 primeiros colocados,
						sendo os seguintes valores:
						<ul>
							<li className="Rules-bullet-points">
								R$15,00 para o primeiro colocado;
							</li>
							<li className="Rules-bullet-points">
								R$10,00 para o segundo colocado;
							</li>
							<li className="Rules-bullet-points">
								R$5,00 para o terceiro colocado;
							</li>
						</ul>
					</p>
				</div>
			</div>
		</div>
	);
}

export default Rules;
